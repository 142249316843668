<template>
  <div class="whole">
	<!-- 提币信息确认 -->
	<!-- <common-head url="/withdrawMoney" :title="$t('system.tibiConfirmInfo')" :is-return="true"></common-head> -->
	<van-nav-bar
	    :title="$t('system.tibiConfirmInfo')"
	    left-arrow
	    @click-left="onClickLeft"
	    safe-area-inset-top
	    fixed
	    z-index="9999"
	/>
	<div class="g_safe_top">
		<div class="layoutBox bgGray">
			<div class="contWhiteBox">
				<div class="list-cell-li">
					<span class="mainFontLightColor">{{ $t('myPledge.currency')}}</span>
					<strong class="mainFontColor">{{ dataForm.token }} 
						<!-- <span class="mainColor1 typebtn">
						  {{ dataForm.mainchain }}
						</span> -->
					</strong>
				</div>
				<div class="list-cell-li">
					<span class="mainFontLightColor">{{ $t('xinzeng.tibidizhi')}}</span>
					<div class="address">
						<strong class="mainFontColor">{{ dataForm.address }}</strong>
					</div>
				</div>
				<div class="list-cell-li">
					<span class="mainFontLightColor">{{ $t('system.Arrivalquantity')}}</span>
					<strong class="mainFontColor">{{ dataForm.arrival }}</strong>
				</div>
			</div>
			<div class="contWhiteBox" style="margin-bottom: 1.4rem;">
				<div class="comTips comTipsPadd">
					<h3 class="caption">{{ $t('system.RiskStatement')}}</h3>
					<p>{{ $t('system.riskTipsText.one')}}</p>
					<p>{{ $t('system.riskTipsText.two')}}</p>
					<p>{{ $t('system.riskTipsText.three')}}</p>
					<p>{{ $t('system.riskTipsText.four')}}</p>
					<p>{{ $t('system.riskTipsText.five')}}</p>
				</div>
			</div>
			<!-- <div class="comBtnStyle" @click="confirmWithdraw()">{{ $t('system.yes') }}</div> -->
			<div class="comBtnStyle" @click="WithdrawCode()">{{ $t('system.yes') }}</div>
		</div>
		
	</div>
	
  </div>
</template>

<script>
import commonHead from '@/components/commonHead';
export default {
	name: "withdrawMoneyInfo",
  components: { commonHead },
  data() {
    return {
	  // 20210415[start]--------
	  dataForm: this.$route.query.dataForm,
    };
  },
  created() {
    if (window.plus) {
      this.plusReady();
    } else {
      document.addEventListener("plusready", this.plusReady, false); //
    }
	// 20210415--------
	this.prepareWithdraw();
	console.log('dataForm', this.dataForm)
  },
  methods: {
	  onClickLeft() {
	  	this.$router.back(-1);
	  },
	  getInitData() {
		  // username
	  },
	  // ----------20200410[start]----------
	  WithdrawCode() {
		  // this.$router.push('checkVerifyCode');
		  this.$router.push({
			  path: '/checkVerifyCode',
			  query: {
				  dataForm: this.dataForm
			  }
		  });
	  },
	  

    // 申请提现
    prepareWithdraw() {
      this.$post2("Pay/Api/Index/prepareWithdrawConfig", {})
        .then((res) => {
          // console.log(res);
          this.dataForm.txid = res.txid;
        })
        .catch((err) => {
          console.log(err);
        });
    },
	
  },
};
</script>

<style scoped lang="less">
// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 1.76rem);
	padding: 0.2rem;
	font-family: PingFangSC-Regular;
}


.list-cell-li {
	padding: 0.2rem 0;
	margin: 0 0.2rem;
	border-bottom: 1px solid #EDEDED;
	// line-height: .45rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	&:last-child {
		border-bottom: 0;
	}
	.address {
		font-size: 0.24rem;
		color: #333333;
		width: 3.72rem;
		word-break: break-all;
		text-align: right;
	}
	.typebtn {
		padding: .02rem .12rem;
		background: #EFF7FF;
		border-radius: .04rem;
		margin-left: .1rem;
	}
}
.comTips {
	color: #666666;
	font-size: .28rem;
	line-height: .45rem;
	&.comTipsPadd {
		color: #666666;
		font-size: .28rem;
		line-height: .4rem;
		padding: .25rem;
		padding-bottom: 0.8rem;
	}
	.caption {
		color: #333333;
		font-size: .28rem;
		font-weight: bold;
		line-height: .45rem;
		margin-bottom: .25rem;
	}
	p {
		margin-bottom: .25rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.comBtnStyle {
	color: #333333;
	font-size: .32rem;
	text-align: center;
	height: .88rem;
	line-height: .88rem;
	background: #FAD538;
	border-radius: 0.16rem;
}
.contWhiteBox {
	margin-bottom: 0.2rem;
	border-radius: 0.16rem;
}
.mainFontLightColor {
	font-size: 0.28rem;
}
.mainFontColor {
	color: #333333 !important;
	font-size: 0.24rem;
}
// ----------20200411[end]-----------
</style>
